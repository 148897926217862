const Degrees_CertificationsInfo=[{
    title: 'Swimming Initiator',
    organization: 'Sport Vlaanderen',
    date: '20 April 2017',
    url:'https://www.sport.vlaanderen/',
    certification_url:''
},{
    title: 'High School Education',
    organization: 'Provinciale Kunsthumaniora Hasselt',
    date: '30 June 2018',
    url:'https://www.pikoh.be/nl',
    certification_url:''
},{
    title: 'Bachelor (Ba) of Applied Information Technology',
    organization: 'Erasmushogeschool Brussel',
    date: '28 June 2022',
    url:'https://www.erasmushogeschool.be/nl',
    certification_url:''
},
{
    title: 'SAP Certified Application Associate - SAP S/4HANA for Financial Accounting Associates (SAP S/4HANA 2021)',
    organization: 'SAP',
    date: 'March 2024',
    url: 'https://training.sap.com/certification/c_ts4fi_2021-sap-certified-application-associate---sap-s4hana-for-financial-accounting-associates-sap-s4hana-2021-g/?',
    certification_url: 'https://www.credly.com/badges/cd46774c-4e0e-4880-a013-e2c79d1cc9ea/linked_in?t=s9nwwu',
    }
]
export default Degrees_CertificationsInfo
