const ProjectsInfo = [{
    technologies: 'ReactJS, HTML/CSS',
    title: 'This Portfolio',
    description: 'I wanted a personal online portfolio, and I created this website. I created this website using React JS and raw CSS. I wanted to retest and reuse my css skills in this portfolio.',
    completionYear: '2022',
    id: 'proj1'
},{
    technologies: 'TailwindCSS, Laravel, HTML, RapidAPI',
    title: 'Personal Finance dashboard',
    description: 'I was tired of using an excel file to track my finances, so I created an online web app. It is accesible from anywhere, but authentication is required. ',
    completionYear: '2022',
    id: 'proj2'
},{
    technologies: 'SAP (Fiori, S4 HANA, CDS, ODATA, UI5, Solman), nodeJS',
    title: 'SAP Solution Manager Dashboard',
    description: 'It was a solo school project (course - Final Work). where I created a dashboard application for SAP Solution Manager. Here it was possible to see and interact with all the created tickets. This was in cooperation with The Value Chain',
    completionYear: '2021',
    id: 'proj3'
},{
    technologies: 'Laravel, TailwindCSS, Php, NodeJs, Python, HTML, mySQL',
    title: 'School Eco-sytem',
    description: 'It was a group project for school (course - Integration Project), we were a group of 17 students where I was responsible for a small team who worked on the Event Website. We were required to make a whole school online infrastructure with a community based event system. The project included Canvas, Active Directory, Event Website, Office 365, a full monitoring aspect.',
    completionYear: '2021',
    id: 'proj4'  
},{
    technologies: 'Rasbperry Pi, TailwindCSS, Laravel, VueJS, Python, HTML, Javascript',
    title: 'Wardrobe/bookcase led Strips',
    description: 'It was a personal project, it was the first time using a Rasbperry pi and led strips. The project consisted in installing led strips in my wardrobe and creating a webapplication to interact with the led strips.',
    completionYear: '2021',
    id: 'proj5'
},{
    technologies: 'TailwindCSS, AWS(ec2), HTML, Javascript',
    title: 'Animal adoption web application',
    description: 'It was a group project for school (course - Software Security). We were a group of 5 students, were I focused on the deployement side of the whole project. We were required to make an interactive website who was fully secured.',
    completionYear: '2021',
    id: 'proj6'
},{  
    technologies: 'SAP Fiori, Javascript',
    title: 'SAP Fiori Webshop',
    description: 'It was a solo school project (course - Business IT Project). Where I made a static webshop using SAP fiori',
    completionYear: '2021',
    id: 'proj7'
},{
    technologies: 'Laravel, Java, HTML, BootstrapCSS, Javascript',
    title: 'Restaurant application - Eatee',
    description: 'It was a group project for school (course - Programming Project II), I was part of a group of 6 students and I took the responsability of team leader on me. We made a school restaurant application with a administarion website and an andriod application.',
    completionYear: '2020',
    id: 'proj8'
},{    
    technologies: 'Libgdx, Java, HTML/CSS, NodeJs',
    title: 'Pokemon Remake',
    description: 'It was a group project for school (course - Programming Project I), I was part of a group of 5 students and I took the responsability of team leader on me. We made a pokemon lookalike desktop game. We also added a website with more functionalities.',
    completionYear: '2020',
    id: 'proj9'
},{    
    technologies: 'Raspberry Pi, ....',
    title: 'Self Build drone',
    description: 'It is a personal Project, who has not yet been started. The idea is to build a drone using a raspberry pi and a lot of sensors. The pi will act as an flight computer with self written code',
    completionYear: 'Upcoming',
    id: 'proj10'
},{
    technologies: 'unknown',
    title: 'Trading Bot',
    description: 'It is a personal Project, who has not yet been started',
    completionYear: 'Upcoming',
    id: 'proj11'
}]
export default ProjectsInfo