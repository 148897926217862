import React , {useEffect} from 'react'
import Title from './subComponents/Title'
import ProjectsInfo from '../data/ProjectsInfo'
import './cssFiles/ProjectsCSS.css'

const Projecs = () => {
    useEffect(() =>{
        showProjects()
    },[])
    useEffect(() =>{
        var element=document.getElementById('container_projects')
        var height=element.getBoundingClientRect().height
        var min_height=element.getBoundingClientRect().top

        if(min_height <= height/3 && min_height>=-height+height/10){
            element.classList.remove('fadeOutEffect')
            element.classList.add('fadeInEffect')
        }else{
            element.classList.remove('fadeInEffect')
            element.classList.add('fadeOutEffect')
        }
    })
    const showProjects=()=>{
        var timing=2
        ProjectsInfo.forEach(proj=>{
           // document.getElementById(proj.id).style.animation=`fadeIn ${timing}s`;
            timing=timing +0.5
        })
    }

    return (
        <div id="container_projects">
            <Title text='Projects' navId="nav_projects"/>
            <div id="projects">
                {ProjectsInfo.map((proj)=>
                    {if(proj.completionYear!=="Upcoming"){
                        return(
                                <div id={proj.id} className="ProjectBoxes">
                                    <h4 className="projHeader">{proj.title}</h4>
                                    <p>{proj.description}</p>
                                    <p className="proj_footer">{proj.completionYear}   ---   {proj.technologies}</p>
                                </div>
                            )
                    }}
                )}
            </div>
        </div>
    )
}

export default Projecs
