import React,{useEffect,useState}from 'react'
import Title from './subComponents/Title'
import ExperienceInfo from '../data/ExperienceInfo'
import './cssFiles/ExperienceCSS.css'

const Technologies = () => {

    const [currentExperience, setCurrentExperience] = useState(0)

    useEffect(()=>{
        initialize()
    },[])
    
    const initialize= ()=>{
        setCurrentExperience(ExperienceInfo.length-1)
        console.log(ExperienceInfo.length)
    }

    useEffect(() =>{
        var element=document.getElementById('container_experiences')
        var height=element.getBoundingClientRect().height
        var min_height=element.getBoundingClientRect().top

        var max_height=height/3-20

        if(min_height <= height/3 && min_height>=-max_height){
            element.classList.remove('fadeOutEffect')
            element.classList.add('fadeInEffect')
        }else{
            element.classList.remove('fadeInEffect')
            element.classList.add('fadeOutEffect')
        }
    })

    return (
        <div id="container_experiences">
            <Title text='Experiences' navId="nav_experiences"/>
            <div id="experiences_container">
                <div id="experience_list_container">
                    {ExperienceInfo.map((data,index)=>
                        <p 
                        id={currentExperience === index ? 'selected_list_item':''}
                        className='list_item'
                        onClick={()=>setCurrentExperience(index)}>
                            {data.organization}
                        </p>
                    )}
                </div>
                <div id="experience_periods_container">
                    {ExperienceInfo[currentExperience].periods.map((period,index)=>
                    <div id="experience_period_container">
                        <p id="experience_header">{period.level}</p>
                        <p>{period.date} ( {period.type} )</p>
                        <p>{period.responsibilities}</p>
                    </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Technologies
