export const Varia = [{
    name: 'Java',
    experience: 'Intermediate',
    type: 'Language',
    startLeaningDate: '2020',
    id: 'Varia_1',
}, {
    name: 'C++',
    experience: 'Beginner',
    type: 'Language',
    startLeaningDate: '2019',
    id: 'Varia_2',
}, {
    name: 'C#',
    experience: 'Beginner',
    type: 'Language',
    startLeaningDate: '2020',
    id: 'Varia_3',
}, {
    name: 'MySQL',
    experience: 'Beginner',
    type: 'Language',
    startLeaningDate: '2019',
    id: 'Varia_4',
}, {
    name: 'Python',
    experience: 'Intermediate',
    type: 'Language',
    startLeaningDate: '2021',
    id: 'Varia_5',
}]
export const Software = [{
    name: 'Java',
    experience: 'Intermediate',
    type: 'Language',
    startLeaningDate: '2020',
    id: 'Soft_1',
}, {
    name: 'C++',
    experience: 'Beginner',
    type: 'Language',
    startLeaningDate: '2019',
    id: 'Soft_2',
}, {
    name: 'C#',
    experience: 'Beginner',
    type: 'Language',
    startLeaningDate: '2020',
    id: 'Soft_3',
}, {
    name: 'MySQL',
    experience: 'Beginner',
    type: 'Language',
    startLeaningDate: '2019',
    id: 'Soft_4',
}, {
    name: 'Python',
    experience: 'Intermediate',
    type: 'Language',
    startLeaningDate: '2021',
    id: 'Soft_5',
}]
export const Web = [{
    name: 'HTML & CSS',
    experience: 'Advanced',
    type: 'Language',
    startLeaningDate: '2019',
    id: 'Web_1',
}, {
    name: 'Javascript',
    experience: 'Advanced',
    type: 'Language',
    startLeaningDate: '2019',
    id: 'Web_2',
}, {
    name: 'Laravel',
    experience: 'Intermediate',
    type: 'Framework',
    startLeaningDate: '2020',
    id: 'Web_3',
}, {
    name: 'VueJS',
    experience: 'Beginner',
    type: 'Framework',
    startLeaningDate: '2021',
    id: 'Web_4',
}, {
    name: 'ReactJS',
    experience: 'Beginner',
    type: 'Framework',
    startLeaningDate: '2021',
    id: 'Web_5',
}, {
    name: 'Bootstrap CSS',
    experience: 'Beginner',
    type: 'Framework',
    startLeaningDate: '2020',
    id: 'Web_6',
}, {
    name: 'Tailwind CSS',
    experience: 'Beginner',
    type: 'Framework',
    startLeaningDate: '2020',
    id: 'Web_7',
}]
export const Sap = [
    {
        name: 'Fiori',
        experience: 'Beginner',
        type: 'Framework',
        startLeaningDate: '2020',
        id: 'SAP_1',
    },
    {
        name: 'Abap',
        experience: 'Beginner',
        type: 'Framework',
        startLeaningDate: '2020',
        id: 'SAP_2',
    },
    {
        name: 'Ai Chatbot',
        experience: 'Beginner',
        type: 'Framework',
        startLeaningDate: '2020',
        id: 'SAP_3',
    },
    {
        name: 'S4 Hana',
        experience: 'Beginner',
        type: 'Framework',
        startLeaningDate: '2020',
        id: 'SAP_4',
    },
    {
        name: 'RPI',
        experience: 'Beginner',
        type: 'Framework',
        startLeaningDate: '2021',
        id: 'SAP_5',
    }
]
export const Aws = [
    {
        name: 'EC2',
        experience: 'Beginner',
        type: 'Servers',
        startLeaningDate: '2021',
        id: 'AWS_1',
    },
    {
        name: 'Amplify',
        experience: 'Beginner',
        type: 'Servers',
        startLeaningDate: '2021',
        id: 'AWS_2',
    }
]