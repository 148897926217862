import React from 'react'
import './cssFiles/FooterCSS.css'

const Footer = () => {
    return (
        <div id="footer_container">
            <p>Build and Designed by Delhoute Matthias --  <a href="https://www.linkedin.com/in/matthias-delhoute/" rel="noreferrer" target="_blank">Contact me</a></p>
            <p>All rights reserved. &copy;	</p>
        </div>
    )
}

export default Footer
