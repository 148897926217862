const OpenSAPCertifications=[{   
    title: 'Design Thinking in a Nutshell',
    courseUrl: 'https://open.sap.com/courses/dt2',
    date: 'Januari 2022',
    recordOfAchievement: 'https://open.sap.com/verify/xokeg-pesad-bihig-gimog-duvez',
    confirmationOfParticipation: true,
    organization: 'OpenSAP',
},{
    title: 'SAP Business Technology Platform in a Nutshell',
    courseUrl: 'https://open.sap.com/courses/btp1',
    date: 'Januari 2022',
    recordOfAchievement: 'https://open.sap.com/verify/xoniv-tacad-togom-hosic-lytep',
    confirmationOfParticipation: true,
    organization: 'OpenSAP',
},{
    title: 'Developing and Extending SAP Fiori Elements Apps',
    courseUrl: 'https://open.sap.com/courses/fiori-ea1',
    date: 'July 2021',
    recordOfAchievement: 'https://open.sap.com/verify/xehir-sidyr-zydaz-nezur-miruv',
    confirmationOfParticipation: true,
    organization: 'OpenSAP',
},{
    title: 'Exploring the World of AI',
    courseUrl: 'https://open.sap.com/courses/ai1',
    date: 'May 2021',
    recordOfAchievement: 'https://open.sap.com/verify/xudeb-socap-magoh-redan-dygob',
    confirmationOfParticipation: true,
    organization: 'OpenSAP',
},{
    title: 'A Step-by-Step Introduction to Process Mining',
    courseUrl: 'https://open.hpi.de/courses/processmining2021',
    date: 'May 2021',
    recordOfAchievement: 'https://open.hpi.de/verify/xuveb-nenem-gevel-rakog-kesef',
    confirmationOfParticipation: true,
    organization: 'OpenHPI',
},{
    title: 'Digital Entrepreneurship',
    courseUrl: 'https://open.hpi.de/courses/digital_entrepreneurship2021',
    date: 'May 2021',
    recordOfAchievement: 'https://open.hpi.de/verify/xezet-cepev-sohyp-ganag-sadil',
    confirmationOfParticipation: true,
    organization: 'OpenHPI',
},{
    title: 'Blockchain revealing the myth',
    courseUrl: 'https://open.hpi.de/courses/blockchain2021',
    date: 'March 2021',
    recordOfAchievement: 'https://open.hpi.de/verify/xenag-rocep-gymim-vutyk-pugom',
    confirmationOfParticipation: true,
    organization: 'OpenHPI',
},{
    title: 'Digital Identities',
    courseUrl: 'https://open.hpi.de/courses/identities2021',
    date: 'February 2021',
    recordOfAchievement: 'https://open.hpi.de/verify/xufav-recuv-hemyp-vulyg-fibut',
    confirmationOfParticipation: true,
    organization: 'OpenHPI',
},{      
    title: 'Cyberthreats by Malware',
    courseUrl: 'https://open.hpi.de/courses/malware2021',
    date: 'February 2021',
    recordOfAchievement: 'https://open.hpi.de/verify/xohin-vilug-hycoh-fyseb-bokir',
    confirmationOfParticipation: true,
    organization: 'OpenHPI',
},{      
    title: 'How To build chatbot with SAP Conversational AI',
    courseUrl: 'https://open.sap.com/courses/cai1',
    date: 'December 2020',
    recordOfAchievement: 'https://open.sap.com/verify/xezap-tezur-kezif-gutap-selel',
    confirmationOfParticipation: true,
    organization: 'OpenSAP',
},{
    title: 'SAP Fiori Overview: Design, Develop and Deploy',
    courseUrl: 'https://open.sap.com/courses/fiori3',
    date: 'Februari 2022',
    recordOfAchievement: '',
    confirmationOfParticipation: true,
    organization: 'OpenSAP',
},{
    title: 'Building Applications with SAP Cloud Application Programming Model',
    courseUrl: 'https://open.sap.com/courses/cp7',
    date: 'Februari 2022',
    recordOfAchievement: '',
    confirmationOfParticipation: true,
    organization: 'OpenSAP',
},{
    title: 'Improve Business Processes with SAP Workflow Management',
    courseUrl: 'https://open.sap.com/courses/btp2',
    date: 'Februari 2022',
    recordOfAchievement: '',
    confirmationOfParticipation: true,
    organization: 'OpenSAP',
},{
    title: 'SAP Solution Manager for SAP S/4HANA Implementation in a Nutshell',
    courseUrl: 'https://open.sap.com/courses/solman1',
    date: 'May 2021',
    recordOfAchievement: '',
    confirmationOfParticipation: true,
    organization: 'OpenSAP',
},{
    title: 'SAP Leonardo Machine Learning Foundation An Introduction',
    courseUrl: 'https://open.sap.com/courses/leo5',
    date: 'April 2021',
    recordOfAchievement: '',
    confirmationOfParticipation: true,
    organization: 'OpenSAP',
},{
    title: 'SAP Data Intelligence For Enterprise AI',
    courseUrl: 'https://open.sap.com/courses/di1',
    date: 'April 2021',
    recordOfAchievement: '',
    confirmationOfParticipation: true,
    organization: 'OpenSAP',
},{
    title: 'Confidential Communication in the Internet',
    courseUrl: 'https://open.hpi.de/courses/confidentialcommunication2021',
    date: 'January 2021',
    recordOfAchievement: '',
    confirmationOfParticipation: true,
    organization: 'OpenHPI',
}]

export default OpenSAPCertifications