import React , {useEffect} from 'react'
import Title from './subComponents/Title'
import OpenSAPCertifications from '../data/OpenSAPCertifications'
import './cssFiles/OpenSAPCertCSS.css'

const SAPCertificates = () => {
    useEffect(() =>{
        showCerts()
    })
    useEffect(() =>{
        var element=document.getElementById('container_open_sap_certificates')
        var height=element.getBoundingClientRect().height
        var min_height=element.getBoundingClientRect().top

        if(min_height <= height/3 && min_height>=-height+height/10){
            element.classList.remove('fadeOutEffect')
            element.classList.add('fadeInEffect')
        }else{
            element.classList.remove('fadeInEffect')
            element.classList.add('fadeOutEffect')
        }
    })
    const showCerts=()=>{
        var timing=2
        OpenSAPCertifications.forEach(cert=>{
            //document.getElementById(cert.id).style.animation=`fadeIn ${timing}s`;
            timing=timing +1
        })
    }

    return (
        <div id="container_open_sap_certificates">
            <Title text='Open SAP Certificates' navId="nav_sap_certificates"/>
            <table id="certs">
                <thead>

                </thead>
                <tbody>
                    {OpenSAPCertifications.map((cert) =>
                        cert.recordOfAchievement !== '' && (
                            <tr key={cert.id} className="CertBoxes">
                                <td>
                                    <a
                                        href={cert.recordOfAchievement}
                                        rel="noreferrer"
                                        target="_blank"
                                        className="certHeaders"
                                    >
                                        {cert.title}
                                    </a>
                                </td>
                                <td>{cert.date}</td>
                                <td>{cert.organization}</td>
                            </tr>
                        )
                    )}

                </tbody>
            </table>
        </div>
    )
}

export default SAPCertificates
