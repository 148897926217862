import React,{useEffect,useState}from 'react'
import Technologies from './Technologies'
import Experiences from './Experiences'
import Projects from './Projects'
import Intro from './Intro'
import SAPCertificates from './OpenSAPCertificates'
import Degrees from './Degrees_Certifications'
import './cssFiles/ContentCSS.css'
import Footer from './Footer'
import NavBar from './NavBar'
import './cssFiles/EffectsCSS.css'

const Content = () => {
    const [scrollPosition, setScrollPosition] = useState(0)

    const handleScroll=()=>{
        const position = window.pageYOffset;
        setScrollPosition(Math.floor(position))
    }
    useEffect(() =>{
        window.addEventListener('scroll',handleScroll)
        return()=>{
            window.removeEventListener('scroll',handleScroll)
        }
    },[])
    
    return ( 
        <div id = "fullContainer" >
            <NavBar />

            <Intro />
            <Experiences/>
            <Technologies/>
            <Degrees/>
            <SAPCertificates/>
            <Projects/>

            <Footer />
        </div>
    )
}

export default Content