import React from 'react'
import './cssFiles/IntroCSS.css'

const Intro = () => {
    return (
        <div id="container_intro" className="introEffect">
            <h1 id="introName">Hello, I'm <b className="important">Matthias Delhoute</b></h1>  
            <p>Welcome to my portfolio! I'm currently a Consultant at Deloitte Belgium, specializing in the SAP Finance Transformation team.</p>
            <p>With a solid background in Applied Computer Sciences, I'm excited to share my journey and projects here. Thank you for stopping by, and I encourage you to explore my experiences.</p>
            <p>As a passionate IT enthusiast, I thrive on embracing new challenges and learning opportunities. From coding to troubleshooting, I approach technology with enthusiasm and dedication. While I have expertise across various domains, I particularly enjoy delving into full stack development, especially in crafting solutions for business applications.</p>
            <p>When I'm not immersed in technology, I enjoy pushing my physical limits. You might find me training for a Half Ironman during my downtime, as I believe in maintaining a balance between mental agility and physical endurance.</p>
        </div>
    )
} 

export default Intro