import React,{useEffect,useState} from 'react'
import './cssFiles/NavBarCSS.css'
import { FaLinkedin, FaBars  } from 'react-icons/fa';

const NavBar = () => {
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

    useEffect(() =>{

    })

    const mobileToggleButtonClicked=()=>{
        console.log('clicked')
        if(window.innerWidth <= 992){
            if(mobileMenuVisible){
                hideMenu()
            }else{
                showMenu()
            }
        }   
    }
    const showMenu=()=>{
        var button=document.getElementById('open_nav_bar_button')
        var list=document.getElementById('navbar_list')
        button.style.display="none"
        list.style.display='block'
        setMobileMenuVisible(true)
        document.body.style.overflow = 'hidden';
    }
    const hideMenu=()=>{
        var button=document.getElementById('open_nav_bar_button')
        var list=document.getElementById('navbar_list')
        button.style.display="block"
        list.style.display='none'
        setMobileMenuVisible(false)
        document.body.style.overflow = 'unset';
    }
    return (
        <div id="navbar_container">
            <button id="open_nav_bar_button" onClick={mobileToggleButtonClicked}><FaBars/></button>

            <ul id='navbar_list'>
                <li><a className="navbar_header" href="#container_intro" rel="noreferrer" onClick={mobileToggleButtonClicked}>Matthias Delhoute</a></li>
                <li><a className="navbar_items" href="#container_experiences" rel="noreferrer"  onClick={mobileToggleButtonClicked}> \ Experiences</a></li>
                <li><a className="navbar_items" href="#container_technologies" rel="noreferrer"  onClick={mobileToggleButtonClicked}>\ Technologies</a></li>
                <li><a className="navbar_items" href="#container_degrees_certifications" rel="noreferrer"  onClick={mobileToggleButtonClicked}>\ Degrees & Certifications</a></li>
                <li><a className="navbar_items" href="#container_open_sap_certificates" rel="noreferrer"  onClick={mobileToggleButtonClicked}>\ Open SAP Certificates</a></li>
                <li><a className="navbar_items" href="#container_projects" rel="noreferrer"  onClick={mobileToggleButtonClicked}>\ Projects</a></li>
                <li><a className="navbar_items" href="https://www.linkedin.com/in/matthias-delhoute/" rel="noreferrer" target="_blank"  onClick={mobileToggleButtonClicked}><FaLinkedin className="icons"/></a></li>
                <li><button className="navbar_items" onClick={mobileToggleButtonClicked} id="close_nav_bar_button">Close</button></li>
            </ul>
        </div>
    )
}

export default NavBar
