import React , {useEffect} from 'react'
import Title from './subComponents/Title'
import Degrees_CertificationsInfo from '../data/Degrees_CertificationsInfo'
import './cssFiles/DegreesCertsCSS.css'

const Degrees = () => {
    useEffect(() =>{

    },[])

    useEffect(() =>{
        var element=document.getElementById('container_degrees_certifications')
        var height=element.getBoundingClientRect().height
        var min_height=element.getBoundingClientRect().top

        var max_height=height/3-20

        if(min_height <= height/3 && min_height>=-max_height){
            element.classList.remove('fadeOutEffect')
            element.classList.add('fadeInEffect')
        }else{
            element.classList.remove('fadeInEffect')
            element.classList.add('fadeOutEffect')
        }
    })


    return (
        <div id="container_degrees_certifications">
            <Title text='Degrees & Certifications' navId="nav_degrees" />
            <div id="degrees">
                {Degrees_CertificationsInfo.map((deg,index)=>
                    <div className="DegreeBoxes">
                        <h4 className="degHeader">{deg.title}</h4>
                        <a className="degrees_link" href={deg.url} rel="noreferrer" target="_blank">{deg.organization}</a>
                        <a className="degrees_link" href={deg.certification_url} rel="noreferrer" target="_blank">Certificate</a>
                        <p>{deg.date}</p>
                    </div>                
                )}
            </div>
        </div>
    )
}

export default Degrees
