import React,{useEffect} from 'react'
import Title from './subComponents/Title'
import {Software,Sap,Web,Varia,Aws} from '../data/technologiesInfo'
import './cssFiles/TechnologiesCSS.css'

const Technologies = () => {
    useEffect(() =>{
        var element=document.getElementById('container_technologies')
        var height=element.getBoundingClientRect().height
        var min_height=element.getBoundingClientRect().top

        var max_height=height/3-20

        if(min_height <= height/3 && min_height>=-max_height){
            element.classList.remove('fadeOutEffect')
            element.classList.add('fadeInEffect')
        }else{
            element.classList.remove('fadeInEffect')
            element.classList.add('fadeOutEffect')
        }
    })

    return (
        <div id="container_technologies">
            <Title text='Technologies' navId="nav_technologies"/>
            <p id="techTxt">Here are my most used/known Technologies</p>
            <div id="techList">
                <div>
                    <h4 className="techHeaders">AWS Tech</h4>
                    <ul>
                        {Aws.map(tech=>
                            <li>{tech.name}</li>
                        )}
                    </ul>
                </div>
                <div>
                    <h4 className="techHeaders">Varia Tech</h4>
                    <ul>
                        {Varia.map(tech=>
                            <li>{tech.name}</li>
                        )}
                    </ul>
                </div>
                <div>
                    <h4 className="techHeaders">Web Tech</h4>
                    <ul>
                        {Web.map(tech=>
                            <li>{tech.name}</li>
                        )}
                    </ul>
                </div>
                <div>
                    <h4 className="techHeaders">SAP Tech</h4>
                    <ul>
                        {Sap.map(tech=>
                            <li>{tech.name}</li>
                        )}
                    </ul>
                </div>
                <div>
                    <h4 className="techHeaders">Software Tech</h4>
                    <ul>
                        {Software.map(tech=>
                            <li>{tech.name}</li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Technologies
