const Degrees_CertificationsInfo = [
    {
        organization: "Gamma Tienen",
        url: 'https://www.gamma.be/nl',
        periods: [
            {date:'Summers of: 2015 + 2016 + 2017 + 2018 + 2019',level:'Student Summer Jobs - Shop assistant',type:'Student Job',responsibilities:'Helping customers, Replenishing shelves, Preparing/releasing orders'},
        ],
    },
    {
        organization: "Pall Lifesciences Belgium BVBA",
        url: 'https://www.pall.com/en/laboratory/locations/pall-life-sciences-belgium.html',
        periods: [
            {date:'Summers of: 2019 + 2020 + 2021',level:'Student Summer Jobs - Cleanroom Operator',type:'Student Job',responsibilities:'Working very precisely, paying attention to details, work quickly and well'},
        ],
    },
    {
        organization: 'Tiense Zwemmers',
        url: 'https://www.facebook.com/Tiense-Zwemmers-129098320516305/',
        periods: [
            {date:'2015 -> 2019',level:'Swim Instructor / Coach',type:'Volentary',responsibilities:'Set up training schedules, ensure safety, time management, individual coaching, accompany swimmers during practices and competitions, inform parents, manage group, teach/enhance swimming techniques'},
        ],
    },{
        organization: 'Swimming Team Tienen',
        url: 'https://www.facebook.com/swimmingteamtienen/',
        periods: [
            {date:'2017 -> 2019',level:'Swim Instructor / Coach',type:'Volentary',responsibilities:'Set up training schedules, ensure safety, time management, individual coaching, accompany swimmers during practices and competitions, inform parents, manage group, teach/enhance swimming techniques'},
        ],
    },{
        organization: 'Tiense Regio United Swimming Team',
        date: '2019 -> 2022',
        jobTitle: 'Swim Instructor / Coach',
        url: 'https://www.zwemclubtrust.be',
        periods: [
            {date:'2019 -> 2022',level:'Swim Instructor / Coach',type:'Volentary',responsibilities:'Set up training schedules, ensure safety, time management, individual coaching, accompany swimmers during practices and competitions, inform parents, manage group, teach/enhance swimming techniques'},
        ],

    }, {
        organization: 'Deloitte',
        url: 'https://www2.deloitte.com/be/en.html',
        periods: [
            {date:'Februari to May 2022',level:'SAP Finance - Trainee',type:'Internship',responsibilities:'Assist with big project (SAP upgrade), follow-up on project statuses, meet deadlines, provide and receive feedback, very communication oriented'},
            {date:'September 2022 -> June 2024',level:'SAP Finance - Business Analyst',type:'full-time',responsibilities:'Assist with big project (SAP upgrade), follow-up on project statuses, meet deadlines, provide and receive feedback, very communication oriented'},
            {date:'June 2024 -> Now',level:'SAP Finance - Consultant',type:'full-time',responsibilities:'Assist with big project (SAP upgrade), follow-up on project statuses, meet deadlines, provide and receive feedback, very communication oriented'}
        ],
    }
]
export default Degrees_CertificationsInfo